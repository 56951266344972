import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import image from '../../assets/images/not-found.png';
import Title from '../../components/PageTitle';
import Monitoring from '../../utils/monitoring';

export const NotFound = () => {
  const [t] = useTranslation();

  Monitoring.captureMessage('Url not found');

  return (
    <Box
      sx={{
        marginLeft: { xxs: 2, lg: 10 },
      }}
    >
      <Title text="404" color="yellow" width="6%" />

      <Stack
        direction="column"
        spacing={2}
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img src={image} alt="" />
        <Typography variant="titleSection">
          {t('NotFound.errorMessage')}
        </Typography>
      </Stack>
    </Box>
  );
};

export default NotFound;
