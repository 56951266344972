import { Route, Routes, BrowserRouter } from 'react-router-dom';

import ErrorBoundary from './components/ErrorBoundary';
import PagePath from './constants/PagePath';
import AdminCoaches from './containers/AdminCoaches';
import AdminCompanies from './containers/AdminCompanies';
import AdminCompaniesNew from './containers/AdminCompaniesNew';
import AdminCompany from './containers/AdminCompany';
import AdminConfirmEmailAddresses from './containers/AdminConfirmEmailAddresses';
import AdminProduct from './containers/AdminProduct';
import AdminProducts from './containers/AdminProducts';
import AdminProductsNew from './containers/AdminProductsNew';
import AdminProject from './containers/AdminProject';
import AdminProjects from './containers/AdminProjects';
import AdminReports from './containers/AdminReports/AdminReports';
import AdminTraining from './containers/AdminTraining';
import AdminTrainingParticipants from './containers/AdminTrainingParticipants';
import AdminTrainings from './containers/AdminTrainings';
import AdminTrainingsNew from './containers/AdminTrainingsNew';
import Auth from './containers/Auth';
import AvailableContentCheck from './containers/AvailableContentCheck';
import Bookings from './containers/Bookings';
import CoachFinder from './containers/CoachFinder';
import ConfirmRegistration from './containers/ConfirmRegistration';
import Dashboard from './containers/Dashboard';
import FindWorkshops from './containers/FindWorkshops';
import Imprint from './containers/Imprint';
import Leadership from './containers/Leadership';
import ModeratorFinder from './containers/ModeratorFinder';
import NotFound from './containers/NotFound';
import PrivacyPolicy from './containers/PrivacyPolicy';
import Settings from './containers/Settings';
import SignOut from './containers/SignOut';
import Welcome from './containers/Welcome';

const App = () => (
  <BrowserRouter>
    <AvailableContentCheck />
    <Routes>
      <Route path="/" element={<Auth />}>
        <Route path={PagePath.SIGNOUT} element={<SignOut />} />
        <Route path={PagePath.IMPRINT} element={<Imprint />} />
        <Route path={PagePath.PRIVACY_POLICY} element={<PrivacyPolicy />} />
        <Route path={PagePath.SETTINGS} element={<Settings />} />
        <Route path={PagePath.DASHBOARD} element={<Dashboard />} />
        <Route path={PagePath.COACH_FINDER} element={<CoachFinder />} />
        <Route path={PagePath.ELEARNING} element={<Leadership />} />
        <Route path={PagePath.BOOKINGS} element={<Bookings />} />
        <Route path={PagePath.MODERATOR_FINDEN} element={<ModeratorFinder />} />
        <Route path={PagePath.ADMIN_PROJECTS} element={<AdminProjects />} />
        <Route path={PagePath.ADMIN_COMPANIES} element={<AdminCompanies />} />

        <Route
          path={PagePath.ADMIN_COMPANIES_NEW}
          element={<AdminCompaniesNew />}
        />
        <Route path={PagePath.ADMIN_TRAININGS} element={<AdminTrainings />} />
        <Route path={PagePath.ADMIN_TRAINING} element={<AdminTraining />} />
        <Route
          path={PagePath.ADMIN_TRAININGS_PARTICIPANTS_ID}
          element={<AdminTrainingParticipants />}
        />
        <Route path={PagePath.ADMIN_COMPANY} element={<AdminCompany />} />
        <Route
          path={PagePath.ADMIN_COMPANY_PRODUCTS}
          element={<AdminCompany />}
        />
        <Route
          path={PagePath.ADMIN_COMPANY_REDIRECT}
          element={<AdminCompany />}
        />
        <Route
          path={PagePath.ADMIN_COMPANY_GROUPS}
          element={<AdminCompany />}
        />
        <Route path={PagePath.ADMIN_COACHES} element={<AdminCoaches />} />
        <Route
          path={PagePath.ADMIN_TRAININGS_NEW}
          element={<AdminTrainingsNew />}
        />
        <Route path={PagePath.ADMIN_PRODUCTS} element={<AdminProducts />} />
        <Route
          path={PagePath.ADMIN_PRODUCTS_NEW}
          element={<AdminProductsNew />}
        />
        <Route path={PagePath.ADMIN_PRODUCT} element={<AdminProduct />} />
        <Route path={PagePath.ADMIN_PROJECT} element={<AdminProject />} />
        <Route path={PagePath.OPEN_WORKSHOPS} element={<FindWorkshops />} />
        <Route path={PagePath.ADMIN_REPORTS} element={<AdminReports />} />
        <Route
          path={PagePath.ADMIN_VERIFY_EMAIL_ADDRESS}
          element={<AdminConfirmEmailAddresses />}
        />
        <Route
          path={PagePath.CONFIRM_REGISTRATION}
          element={
            <ErrorBoundary>
              <ConfirmRegistration />
            </ErrorBoundary>
          }
        />
        <Route path={PagePath.ANMELDEN} element={<Welcome />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  </BrowserRouter>
);

export default App;
